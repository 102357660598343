/**********************************

      SOTM Web Application
        Home page styles

**********************************/
@import "../variables";
@import "../mixins";

$nominee-left-col-width: calc(128px + #{2 * $base-spacing});

.competition {
  padding: 2 * $base-spacing;

  > p {
    line-height: 1.2;
  }

  hr {
    margin: 2 * $base-spacing 0;
    height: 1px;
    background-color: black;
    border: none;
  }

  .section-heading {
    margin-bottom: 2 * $base-spacing;
  }

  .nominee {
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 2 * $base-spacing;
    }

    .nominee-left-col {
      @include flexColWidth($nominee-left-col-width);
      padding-right: 2 * $base-spacing;

      button {
        margin-bottom: $base-spacing;
      }
    }

    .nominee-right-col {
      @include flexColWidth(calc(100% - #{$nominee-left-col-width}));

      h5 {
        margin-bottom: $base-spacing;
      }

      :not(:last-child) {
        div {
          margin-bottom: $base-spacing;
        }
      }

      .action-buttons {
        flex-shrink: 0;

        span {
          &:not(:nth-child(2)) {
            cursor: pointer;

            &:hover {
              color: $color-accent;
            }
          }
        }
      }
    }
  }
}

.divider {
  padding-left: 2 * $base-spacing;
  padding-right: 2 * $base-spacing;
}

@media screen and (max-width: 425px) {
  .nominee {
    .nominee-left-col {
      @include flexColWidth(100%);
      padding-right: 0;

      img {
        width: 100% !important;
        height: auto !important;
      }
    }

    .nominee-right-col {
      @include flexColWidth(100%);
    }
  }
}
