/**********************************

      SOTM Web Application
        Global stylesheet

**********************************/
@import "variables";
@import "mixins";
@import "pages/home";

// Import brand font: acumin-pro-wide
@import url("https://use.typekit.net/coq0xdl.css");

@include spacings();

* {
  box-sizing: border-box;
  font-family: "acumin-pro-wide", sans-serif;
}

html {
  background: $color-bg-gray;
  font-size: 16px;
  line-height: 1.5;
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
}

main {
  padding-top: $header-height;
}

header {
  background: black;
  position: fixed;
  z-index: 100;
  height: $header-height;
  width: 100%;

  .app-bar {
    display: flex;
    justify-content: space-between;
  }
}

.container {
  padding: $base-spacing;
  max-width: $page-max-width;
  margin: auto;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -$base-spacing;
  margin-right: -$base-spacing;

  &.no-margin {
    margin: 0;
  }
}

.col-half-responsive {
  @include flexColWidth(50%);
  padding: 0 $base-spacing;
}

@media screen and (max-width: 991px) {
  .col-half-responsive {
    width: 100%;
    flex: 0 0 100%;
  }
}

.sticky-top {
  @include stickyTop();
}

.box-with-loader {
  padding: 0 $base-spacing * 2;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
}

.profile-image {
  width: 128px;
  height: auto;
  margin-bottom: $base-spacing;
  background: black;
  box-shadow: $box-shadow;
  -webkit-box-shadow: $box-shadow;
}

.profile-image {
  width: 128px;
  height: auto;
  margin-bottom: $base-spacing;
  background: black;
  box-shadow: $box-shadow;
  -webkit-box-shadow: $box-shadow;
}

.winners-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $base-spacing;
  margin-top: $base-spacing;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  .tail {
    clear: both;
    display: flex;
    flex-direction: row;
    gap: $base-spacing;
    padding: $base-spacing;
  }

  .content {
    width: 100%;
  }
}

.month-name {
  background-color: white;
  height: 50px;
}

.drawer {
  img {
    width: 90px;
    height: 90px;
    margin: 0;
  }

  button {
    color: white;
    justify-content: flex-start;
    text-transform: none;
    padding-left: 0;
    height: 80px;

    &:hover {
      color: $color-accent;
      background-color: inherit;
    }
  }
}

.initials {
  margin-bottom: $base-spacing;
  font-size: 1rem;
  line-height: 1.2;
}

.history-placeholder {
  padding: 2 * $base-spacing $base-spacing 2 * $base-spacing 0;
}

.pos-static {
  position: static !important;
}

.d-inline-block {
  display: inline-block;
}

.text-muted {
  color: $color-text-muted;
}

.text-accent {
  color: $color-accent;
}

.dialog-title {
  h4 {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.mobile-only {
  display: none;
}

.desktop-only {
  display: block;
}

@media screen and (max-width: 991px) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
}

.votes-section {
  display: flex;
  margin-bottom: $base-spacing;

  .vps {
    @include flexColWidth(33.333%);
  }

  .right-padding {
    padding-right: $base-spacing;
  }
}

.dialog-buttons {
  .actions {
    button {
      &:hover {
        background-color: $color-accent;
        color: white;
      }
    }
  }
}

.blurred {
  color: transparent;
  text-shadow: 0 0 15px rgba(0,0,0,0.5);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
