/**********************************

      SOTM Web Application
            Variables

**********************************/

$color-accent: #E30613;
$color-bg-gray: #f0f0f0;
$color-text-muted: #999999;

$page-max-width: 1440px;
$base-spacing: 15px;
$border-width: 1px;
$box-shadow: 0px 2px 5px -1px rgb(0 0 0 / 20%);

$header-height: 78px;
$body-offset: $header-height + $base-spacing
