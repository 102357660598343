@import "../variables";
@import "../mixins";

.success-of-the-year {
  padding: 2 * $base-spacing;

  .logo {
    @include flexColWidth(33%);
  }

  .year-text {
    margin-left: 20%;
    font-size: 2.5vw;
    font-weight: bold;
  }

  @media screen and (max-width: 991px) {
    .logo {
      width: 50%;
      flex: 0 0 100%;
    }
    .year-text {
      margin-left: 30%;
      font-size: 7vw;
      font-weight: bold;
    }
  }
}