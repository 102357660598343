/**********************************

      SOTM Web Application
            Mixins

**********************************/
@import "variables";

@mixin flexColWidth($width) {
  width: #{$width};
  flex: 0 0 #{$width};
}

@mixin transition() {
  transition: 120ms all ease-in-out;
  -webkit-transition: 120ms all ease-in-out;
}

@mixin stickyTop($top: #{$header-height}) {
  position: sticky;
  top: $top;
  height: 100%;
  z-index: 10;
}

@mixin spacings() {
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .ms-0 {
    margin-left: 0 !important;
  }
  .me-0 {
    margin-right: 0 !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }
  .pt-0 {
    padding-top: 0 !important;
  }
  .ps-0 {
    padding-left: 0 !important;
  }
  .pe-0 {
    padding-right: 0 !important;
  }
  @for $i from 1 through 5 {
    .mb-#{$i}x {
      margin-bottom: $base-spacing * $i !important;
    }
    .mt-#{$i}x {
      margin-top: $base-spacing * $i !important;
    }
    .ms-#{$i}x {
      margin-left: $base-spacing * $i !important;
    }
    .me-#{$i}x {
      margin-right: $base-spacing * $i !important;
    }

    .pb-#{$i}x {
      padding-bottom: $base-spacing * $i !important;
    }
    .pt-#{$i}x {
      padding-top: $base-spacing * $i !important;
    }
    .ps-#{$i}x {
      padding-left: $base-spacing * $i !important;
    }
    .pe-#{$i}x {
      padding-right: $base-spacing * $i !important;
    }
  }
}
