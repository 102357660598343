/**********************************

      SOTM Web Application
    Nominee search component

**********************************/

@import './src/styles/variables';
@import './src/styles/mixins';


/*
    1. Styles
*/
.employee-search {
  background: white;
  width: 100%;
  overflow: hidden;

  .es-input-wrapper {
    @include transition();

    position: relative;
    padding: 2 * $base-spacing;
    padding-bottom: 0;

    input {
      width: 100%;
      padding: 0 30px $base-spacing 30px;
      margin-bottom: 2 * $base-spacing;
      border: none;
      border-bottom: $border-width solid black;
      box-shadow: none;
      outline: none;
      font-size: inherit;


      &:focus {
        border-bottom: 1px solid $color-accent;
      }
    }

    .es-search-icon {
      position: absolute;
      top: 2 * $base-spacing;
      left: 2 * $base-spacing;
    }


    &:focus-within {
      color: $color-accent;
    }

    &.is-scrolled {
      box-shadow: $box-shadow;
      -webkit-box-shadow: $box-shadow;
    }
  }

  .es-options {
    overflow: hidden visible;
    margin: 0;
    height: calc(100vh - 100px - #{$header-height} - (2 * #{$base-spacing}));
    padding: 0 $base-spacing $base-spacing;
    flex-wrap: wrap;
    align-items: start;

    .es-option {
      font-size: 1.25rem;
      background: transparent;
      border: none;
      padding: $base-spacing $base-spacing 0;
      line-height: 1.2;
      text-align: left;
      cursor: pointer;
      outline: none;

      img {
        width: 100% !important;
        height: auto !important;
      }

      .initials {
        margin-bottom: $base-spacing;
        font-size: 1rem;
      }

      &:hover {
        background: #f5f5f5;
      }
    }
  }
}


/*
    2. Mixins
*/
@mixin nomineePickerColWidth($width) {
  .employee-search {
    .es-options {
      .es-option {
        @include flexColWidth($width)
      }
    }
  }
}

/*
    3. Media Queries
*/
@media screen and (min-width: 1200px) {
  @include nomineePickerColWidth(25%);
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  @include nomineePickerColWidth(33.333%);
}


@media screen and (max-width: 991px) {
  .employee-search {
    margin-bottom: $base-spacing;

    .es-options {
      max-height: 300px;
    }
  }
}


@media screen and (max-width: 991px) {
  @include nomineePickerColWidth(25%)

}

@media screen and (min-width: 576px) and (max-width: 767px) {
  @include nomineePickerColWidth(33.333%)
}


@media screen and (max-width: 575px) {
  @include nomineePickerColWidth(50%);
}