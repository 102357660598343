.nomination-justification {
  .justification-box {
    display: flex;
    flex-direction: column;
    flex: 1;

    .justification-text {
      flex: 1;
    }

    .justification-text-helpers {
      display: flex;
      flex-shrink: 1;
      flex-direction: row;
      font-size: small;

      .to-few-prompt {
        margin-right: auto;
      }

      .current-length-prompt {
        margin-left: auto;
      }
    }
  }
}