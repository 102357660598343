@import "./src/styles/variables";

.login-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 2 * $base-spacing;

  .login-form {
    width: 100%;
    max-width: 550px;

    button {
      display: inline-block;
      margin: auto;
      min-width: 180px;
    }

    img {
      width: 100%;
      height: auto;
      margin-bottom: $base-spacing * 4;
    }

    @media screen and (max-width: 424px) {
      img {
        margin-bottom: $base-spacing * 2;
      }
    }
  }
}